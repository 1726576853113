const productData = [
    {
      id: 1,
      name: "TOOFAN NET PRO",
      image:
        "https://i.postimg.cc/BvH8MKMq/toofannetpromain.png",
      productType: "openBackHeadphones",
      description: ["OVPN", "UDP", "V2RAY"],
      rating: 5,
      timeLeft: 27,
      reseller: "http://vipnetvpn.xyz",
      appLink: "https://tinyurl.com/ToofanNetPro-Apk"
    },
    {
      id: 2,
      name: "TOOFAN VIP VPN",
      image:
        "https://i.postimg.cc/QtTjmLLS/tooofanvipvpn.png",
      productType: "openBackHeadphones",
      description: ["OVPN", "UDP", "V2RAY"],
      rating: 5,
      timeLeft: 27,
      reseller: "http://vipnetvpn.xyz",
      appLink: "https://tinyurl.com/ToofanVIPnet-apk"
    },
    {
      id: 3,
      name: "TOOFAN IS PRO",
      image:
        "https://i.postimg.cc/mDvCpGPr/toofanispro.png",
      productType: "openBackHeadphones",
      description: ["OVPN", "UDP", "V2RAY"],
      rating: 5,
      timeLeft: 27,
      reseller: "http://vipnetvpn.xyz",
      appLink: "https://tinyurl.com/ToofanisPro-Apk"
    },
    {
      id: 4,
      name: "TOOFAN IS KING",
      image:
        "https://i.postimg.cc/y6t8CJMc/toofanisking.png",
      productType: "openBackHeadphones",
      description: ["OVPN", "UDP", "V2RAY", "SSH", "SLOWDNS"],
      rating: 5,
      timeLeft: 24,
      reseller: "http://vipnetvpn.xyz",
      appLink: "https://gitlab.com/fixbuildvw/vpnapklink/-/raw/main/toofanisking.apk"
    },
    {
      id: 5,
      name: "GOLD NET PRO",
      image:
        "https://i.postimg.cc/cCb4sxX9/goldnetproimg.png",
      productType: "openBackHeadphones",
      description: ["OVPN", "UDP", "V2RAY"],
      rating: 5,
      timeLeft: 24,
      reseller: "https://goldvipvpn.xyz",
      appLink: "https://tinyurl.com/GoldNetProApk"
    },
    {
      id: 6,
      name: "GOLD VIP VPN",
      image:
        "https://i.postimg.cc/DzTffYPT/goldvipvpn.png",
      productType: "openBackHeadphones",
      description: ["OVPN", "UDP", "V2RAY"],
      rating: 5,
      timeLeft: 24,
      reseller: "https://goldvipvpn.xyz",
      appLink: "https://play.google.com/store/apps/details?id=dev.rlb.bestsoft.v2.targetvipvpn"
    },
    {
      id: 7,
      name: "SUPER UDP VPN",
      image:
        "https://i.postimg.cc/1365QZ6B/superudpvpn.png",
      productType: "openBackHeadphones",
      description: ["OVPN", "UDP", "V2RAY"],
      rating: 5,
      timeLeft: 27,
      reseller: "http://supersitevpn.xyz",
      appLink: "https://gitlab.com/fixbuildvw/vpnapklink/-/raw/main/SuperUdpVPN.apk"
    },
    {
      id: 8,
      name: "SUPER NET VIP",
      image:
        "https://i.postimg.cc/Gh1shqcs/Super.png",
      productType: "openBackHeadphones",
      description: ["OVPN", "UDP", "V2RAY"],
      rating: 5,
      timeLeft: 27,
      reseller: "http://supersitevpn.xyz",
      appLink: "https://tinyurl.com/SuperNetVIP-apk"
    }
  ];
  export default productData;
